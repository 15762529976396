"use client";

import React, { FC, useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
  children: React.ReactNode;
}

const variants = {
  hidden: { opacity: 1, x: 0, y: 0 },
  enter: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      duration: 0.4,
      ease: "easeIn",
    },
  },
  exit: { opacity: 0, x: 0, y: 0 },
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const Transition: FC<Props> = ({ children }): JSX.Element => {
  const ref = useRef(null);
  return (
    <AnimatePresence>
      <motion.main
        ref={ref}
        variants={variants}
        initial="hidden"
        animate="enter"
        exit="exit"
        className="flex flex-col min-h-screen w-full overflow-hidden"
      >
        {children}
      </motion.main>
    </AnimatePresence>
  );
};

export default Transition;
