"use client";

import React, { FC, useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import moment from "moment";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { usePathname } from "next/navigation";

interface Props {}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const Footer: FC<Props> = (): JSX.Element => {
  const [year, setYear] = useState<any>(moment().year());
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        if (response.status != 200) {
          throw new Error("Network response was not ok");
        }
        setData(response.data.ip);
      } catch (error) {
        console.log(error);
      }
    };
    fetchIP();
  }, []);

  useEffect(() => {
    if (data) {
      // @ts-ignore
      import("vanilla-cookieconsent/dist/cookieconsent.js").then(() => {
        var cc = window.initCookieConsent();
        cc.run({
          current_lang: "en",
          autoclear_cookies: true, // default: false
          page_scripts: true, // default: false
          cookie_expiration: 365,
          // mode: 'opt-out',                    // default: 'opt-in'; value: 'opt-in' or 'opt-out'
          // delay: 0,                               // default: 0
          // auto_language: '',                      // default: null; could also be 'browser' or 'document'
          // autorun: true,                          // default: true
          // force_consent: false,                   // default: false
          // hide_from_bots: false,                  // default: false
          // remove_cookie_tables: false             // default: false
          cookie_name: "baansindhorn_cookie", // default: 'cc_cookie'
          // cookie_expiration: 182,                 // default: 182 (days)
          // cookie_necessary_only_expiration: 182   // default: disabled
          // cookie_domain: location.hostname,       // default: current domain
          // cookie_path: '/',                       // default: root
          // cookie_same_site: 'Lax',                // default: 'Lax'
          // use_rfc_cookie: false,                  // default: false
          revision: 1, // default: 0
          // value: { ipAddress: data.ipAddress, country: data.countryName, prov: data.stateProv },

          onFirstAction: function (user_preferences, cookie) {
            // callback triggered only once on the first accept/reject action
          },

          onAccept: function (cookie: any, user_preferences: any) {
            cc.set("data", {
              value: {
                ipAddress: data.ip,
                UUID: uuidv4(),
              },
            });

            // if (cc.allowedCategory('analytics')) {

            //   cc.loadScript(`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`, function () {

            //     console.log('analytics enabled!')
            //   });
            // }
          },

          onChange: function (cookie, changed_categories) {
            // callback triggered when user changes preferences after consent has already been given
          },

          languages: {
            en: {
              consent_modal: {
                title: "We use cookies",
                description:
                  'This website uses cookies to enhance your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from.  You can manage your preferences by clicking <button type="button" data-cc="c-settings" class="cc-link">Change Preferences</button>',
                primary_btn: {
                  text: "Accept all",
                  role: "accept_all", // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                  text: "Reject all",
                  role: "accept_necessary", // 'settings' or 'accept_necessary'
                },
              },
              settings_modal: {
                title: "Privacy Preference",
                save_settings_btn: "Save settings",
                accept_all_btn: "Accept all",
                reject_all_btn: "Reject all",
                close_btn_label: "Close",
                cookie_table_headers: [
                  { col1: "Name" },
                  { col2: "Domain" },
                  { col3: "Expiration" },
                  { col4: "Description" },
                ],
                blocks: [
                  {
                    title: "Cookie usage 📢",
                    description:
                      'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="https://siamsindhorn.com/privacy" class="cc-link">privacy policy</a>.',
                  },
                  {
                    title: "Necessary",
                    description:
                      "Necessary cookies are required to help a website usable by enabling core functions and access to secure areas of the website. The website cannot be function properly without these cookies and they are enabled by default and cannot be disabled.",
                    toggle: {
                      value: "necessary",
                      enabled: true,
                      readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                    },
                  },
                  {
                    title: "Performance and Analytics cookies",
                    description:
                      "Analytics cookies help website to understand how visitors interact through the website. These cookies help to improve user experiences by collecting and reporting information. ",
                    toggle: {
                      value: "analytics", // your cookie category
                      enabled: false,
                      readonly: false,
                    },
                    cookie_table: [
                      // list of all expected cookies
                      {
                        col1: "^_ga", // match all cookies starting with "_ga"
                        col2: "google.com",
                        col3: "2 years",
                        col4: "description ...",
                        is_regex: true,
                      },
                      {
                        col1: "_gid",
                        col2: "google.com",
                        col3: "1 day",
                        col4: "description ...",
                      },
                    ],
                  },
                  {
                    title: "Marketing",
                    description:
                      "Marketing cookies are used to track visitors across websites to display relevant advertisements for the individual user and thereby more valuable for publishers and third party advertisers.",
                    toggle: {
                      value: "targeting",
                      enabled: false,
                      readonly: false,
                    },
                  },
                  {
                    title: "More information",
                    description:
                      'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
                  },
                ],
              },
            },
          },

          gui_options: {
            consent_modal: {
              layout: "bar", // box/cloud/bar
              position: "bottom center", // bottom/middle/top + left/right/center
              transition: "slide", // zoom/slide
              swap_buttons: false, // enable to invert buttons
            },
            settings_modal: {
              layout: "box", // box/bar
              // position: 'left',           // left/right
              transition: "slide", // zoom/slide
            },
          },
        });
      });
    }
  }, [data]);

  return (
    <>
      <footer className="w-full h-fit  flex flex-col justify-center items-center relative -mt-1">
        <div className="max-w-[1440px] mx-auto px-4 md:px-6 lg:px-8 xl:px-10  w-full flex flex-col gap-2 justify-between  items-center text-primary">
          <div className="w-full flex  flex-col gap-2 lg:gap-3 lg:flex-row justify-between lg:justify-between items-center border-t border-[#82603f]/30 py-4">
            <div className="w-fit right-5 lg:right-10 flex flex-col lg:flex-row  items-center gap-2 lg:gap-3 text-sm lg:text-base  lg:divide-x  md:justify-start divide-[#82603f]/30 ">
              <div className="flex flex-col lg:flex-row items-center gap-1 lg:gap-2">
                <p className="text-sm  text-[#151514]">
                  More channels to contact us
                </p>
                <div className="flex items-center justify-start gap-[5px] mt-1 ">
                  <Link
                    legacyBehavior
                    href="https://www.facebook.com/Sindhornvillage"
                    className=""
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook_osp"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="33"
                        viewBox="0 0 32.84 32.84"
                        className="cursor-pointer bg-[#82603f] text-white rounded-full duration-200 transition-all group"
                      >
                        <circle
                          id="Ellipse_9"
                          data-name="Ellipse 9"
                          cx="15.67"
                          cy="15.67"
                          r="15.67"
                          transform="translate(0.75 0.75)"
                          fill="none"
                          stroke="#82603f"
                          strokeWidth="1.5"
                          className=""
                        />
                        <path
                          id="Path_548020"
                          data-name="Path 548020"
                          d="M89.224,61.228h-2.91v-6.2H84.86V52.647h1.454V51.209c0-1.947.821-3.106,3.153-3.106h1.941v2.388H90.2c-.909,0-.969.334-.969.956v1.194h2.194l-.255,2.389H89.224Z"
                          transform="translate(-71.834 -38.578)"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </Link>

                  <Link
                    legacyBehavior
                    href="https://www.instagram.com/sindhornvillage"
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram_sdb"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="33"
                        viewBox="0 0 32.84 32.84"
                        className="cursor-pointer  bg-[#82603f]  text-white rounded-full duration-200 transition-all group"
                      >
                        <circle
                          id="Ellipse_9"
                          data-name="Ellipse 9"
                          cx="15.67"
                          cy="15.67"
                          r="15.67"
                          transform="translate(0.75 0.75)"
                          fill="none"
                          stroke="#82603f"
                          strokeWidth="1.5"
                          className=""
                        />

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="24"
                          viewBox="0 0 32.84 32.84"
                        >
                          <path
                            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                            fill="currentColor"
                            transform="translate(9 10)"
                          />
                        </svg>
                      </svg>
                    </a>
                  </Link>
                </div>
              </div>

              <div className="flex items-center gap-3 divide-x divide-[#82603f]/30">
                <div className="flex items-center justify-center lg:pl-3">
                  <Link
                    legacyBehavior
                    href={"https://siamsindhorn.com/privacy"}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="goto_privacy"
                      className="hover:scale-105 duration-300  transition-all cursor-pointer outline-none border-none text-sm  hover:font-medium"
                    >
                      Privacy
                    </a>
                  </Link>
                </div>

                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    data-cc="c-settings"
                    className="pl-3 hover:scale-105 duration-300 cursor-pointer outline-none border-none transition-all text-sm  hover:font-medium"
                  >
                    Cookies
                  </button>
                </div>
              </div>
            </div>

            <div className="flex items-center h-full lg:gap-3 lg:divide-x divide-[#82603f]/30 w-fit flex-col lg:flex-row">
              <Link legacyBehavior href={"https://siamsindhorn.com/"} passHref>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="website"
                  className="h-10 w-28 relative  hidden lg:block "
                >
                  <Image
                    src={"/assets/logo-ssd.webp"}
                    alt="logo"
                    priority
                    width={0}
                    height={0}
                    sizes="100vw"
                    unoptimized={true}
                    style={{
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                    className="w-full h-full"
                  />
                </a>
              </Link>

              <div className="text-center text-sm  pl-3 flex items-center">
                <p className="text-sm text-[#151514]">
                  Copyright © 2024 {year != "2024" && -year}{" "}
                  <Link href="/some-page" legacyBehavior passHref>
                    <a
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Siam Sindhorn"
                      className="font-medium cursor-pointer px-1 italic text-[#82603f]"
                    >
                      Siam Sindhorn Co., Ltd.
                    </a>
                  </Link>{" "}
                  All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
